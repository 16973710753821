import React from 'react';
import './FormLabelDiscount.css';
import { useLocation } from 'react-router-dom';
import { translations } from '../../utils/translations/translations';
import { useSelector } from 'react-redux';

function FormLabelDiscount({ item, tariffesListClass, ownPersons }) {
  const location = useLocation();
  const currentUser = useSelector((state) => state.currentUser);
  const language = useSelector(state => state.language);
  const testDiscount = false;
  const currentClass =  location.pathname.replace(
    '/tariffes/',
    ''
  ) === '/gift' ? 'gift-discount' : (location.pathname.replace(
    '/tariffes/',
    '')
  )

  return (
    <span
      className={`form-label-discount ${currentClass} ${tariffesListClass} `
      }
    >
      {(tariffesListClass == 'own-list' || tariffesListClass === 'nolimit-list' || ownPersons) ? null : (currentUser.discount ? '-' : translations[language].textTips.discount)}
      {item}
      {(tariffesListClass == 'own-list' || tariffesListClass === 'nolimit-list' || ownPersons) ? null :
        translations[language].textTips.percent
      }

    </span>
  );
}

export default FormLabelDiscount;
