import React from 'react';
import './Success.css';
import AppButton from '../AppButton/AppButton';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { translations } from '../../utils/translations/translations';

function Success() {
  const tg = window.Telegram.WebApp;
  const language = useSelector(state => state.language);
  const navigate = useNavigate();
  return (
    <section className='success'>
      <p className='success__text'>
        {translations[language].payment.paymentSuccessTitle}
      </p>
      <p className='success__text-secondary'>
        {translations[language].payment.paymentSuccessText}
      </p>
      <div className='success__button-box'>
        <AppButton
          text={translations[language].appButton.backToTelegram}
          currentClass='success__app-button success__app-button-secondary'
          handler={() => tg.close()}
        />
        <AppButton
          text={translations[language].appButton.mainMenu}
          currentClass='success__app-button margin-top'
          handler={() => navigate('/')}
        />
      </div>
    </section>
  );
}

export default Success;
