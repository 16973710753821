import React from 'react';
import './FormLabel.css';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import FormLabelDiscount from '../FormLabelDiscount/FormLabelDiscount';
import { setNextTariff } from '../../redux/actions/actions';
import AppButton from '../AppButton/AppButton';
import { useSelector } from 'react-redux';
import { translations } from '../../utils/translations/translations';

function FormLabel({
  elementValue,
  handler,
  name,
  currentClass,
  title,
  text,
  valueMain,
  valueSecondary,
  profitValue,
  defaultChecked,
  isDiscounted,
  disabled,
  currentUserDiscount,
  isRecommended,
  tariffName,
  profit,
  isProfit,
  valueCount,
  tariffesListClass,
  ownPersons,
  ownPackageLimited
}) {
  const [discountItems, setDiscountItems] = React.useState([]);
  const [ownLimitedMessage, setOwnLimitedMessage] = React.useState(false);
  const language = useSelector(state => state.language);

  const handleOwnLimited = () => {
    setOwnLimitedMessage(true);
  }
 
  
  const valueDiscounted = Math.round(
    parseInt(valueMain) - (parseInt(valueMain) / 100) * currentUserDiscount
  );

  React.useEffect(() => {
    setDiscountItems(
      [
        currentUserDiscount ? '-' + currentUserDiscount + '%' : null,
        profitValue,
        isRecommended ? 'Рекомендуем' : null,
      ].filter((el) => !!el)
    );
  }, [currentUserDiscount, profitValue, isRecommended]);
  return (
    <motion.label
      className={`form-label ${currentClass} ${ownPackageLimited && 'own-limited'} ${ownLimitedMessage && 'own-limited-message'} ${isRecommended && 'recomended'}`}
      whileTap={{ scale: 0.95 }}
    >
      <input
        onChange={(e) => {
          tariffName && setNextTariff(tariffName);
          handler(e.target.value);
          
        }}
        className='form-label__radio-input'
        type='radio'
        name={name}
        value={elementValue}
        defaultChecked={defaultChecked}
        disabled={disabled ? true : false}
      />
      <div className='form-label__content'>
        <div className="form-label__content-flex">
        <div className='form-label__text-box'>
          <p
            className={`form-label__title ${
              !!text && 'form-label__title_moved'
            }`}
          >
            {title}
          </p>
          {text && <span className='form-label__text-secondary'>{text}</span>}
        </div>
        <div className='form-label__value'>
          {valueMain && (
            <span
              className={`form-label__value-main form-label__value-main-${currentClass}`}
            
            >
              {currentUserDiscount ? (
                <div className='form-label__disc'>
                  <span className='form-label__value-main_default'>
                    {valueDiscounted + '₽'}
                  </span>
                  <br />
                  <span  className='form-label__value-main_crossed'>
                    {parseInt(valueMain) + '₽'}
                  </span>
                </div>
              ) : (
                valueMain
              )}
            </span>
          )}
          {valueSecondary && (
            <span className='form-label__value-secondary'>
              {valueSecondary}
            </span>
          )}
          {valueCount && (
            <span className='form-label__value-secondary'>
              {valueCount}
            </span>
          )}
        </div>
        </div>
        {ownPackageLimited ? (
        <AppButton
        text={ownLimitedMessage ? translations[language].tariffOwn.ownServersBtn2 : translations[language].tariffOwn.ownServersBtn1}
        currentClass={`app-button bg-white violet small-text margin-top ${ownLimitedMessage && 'disabled-opts'}`}
        handler={handleOwnLimited}/>
        ) : null}
      </div>
        
      {isProfit ? (
        <motion.div className='form-label__discounted-items'>
          
            <FormLabelDiscount ownPersons={ownPersons} tariffesListClass={tariffesListClass} item={profit} currentClass={currentClass} />
         
        </motion.div>
      ) : null}
    </motion.label>
  );
}
FormLabel.propTypes = {
  currentClass: PropTypes.string.isRequired,
  elementValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  handler: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  valueMain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueSecondary: PropTypes.string,
  profitValue: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  currentUserDiscount: PropTypes.number,
  isDiscounted: PropTypes.bool,
  isRecommended: PropTypes.bool,
  tariffName: PropTypes.string,
};
export default FormLabel;
