import React from 'react';
import './ErrorPage.css';
import errorRoboImage from '../../images/error-robo.svg';
import { motion } from 'framer-motion';
import { translations } from '../../utils/translations/translations';
import { useSelector } from 'react-redux';

function ErrorPage() {
  const language = useSelector(state => state.language);
  return (
    <section className='error-page'>
      <motion.img
        animate={{
          rotate: [-10, 10, -10],
          translate: ['7px', '-7px', '7px'],
          transition: { duration: 2, repeat: Infinity },
        }}
        className='error-page__image'
        src={errorRoboImage}
        alt='error face'
      />
      <h1 className='error-page__title'>
        {translations[language].errorPage.errorTitle}
      </h1>
          <p className='error-page__text'>
            {translations[language].errorPage.errorTextNew1}
            <br />
            {translations[language].errorPage.errorTextNew2}
            <br />
            {translations[language].errorPage.errorTextNew3}
            <br /> 
            {translations[language].errorPage.errorTextNew4}
            <br />
            {translations[language].errorPage.errorTextNew5}
            <br />
            {translations[language].errorPage.errorTextNew6}
            <br />
            {translations[language].errorPage.errorTextNew7}
          </p>
    </section>
  );
}

export default ErrorPage;
