import React from "react";
import { translations } from '../../utils/translations/translations';
import './SmartToggle.css';
import { useSelector } from "react-redux";
import { motion } from 'framer-motion';

const SmartToggle = ({onChange, currentClass, handleMore, gift}) => {
    const [isChecked, setIschecked] = React.useState(false);
    const language = useSelector(state => state.language);
    const handleCheck = () => {
        setIschecked(curr => !curr)
        onChange(isChecked)
    }

    const toggleVariants = {
        nosmart: {
            x: '0%'
        },
        smart: {
            x: '100%'
        }
    }

    return(
        <div className={`smart-toggle ${currentClass}`}>
            <div className={isChecked === true ? `smart-toggle__desc ${currentClass}` : 'smart-toggle__desc'}>
                <div className={`smart-toggle__desc-title ${currentClass}`}>
                    {translations[language].tariffes.tariffSmartTitle}
                </div>
                {/* <div className={`smart-toggle__desc-text ${gift} ${(currentClass === 'own' || currentClass === 'nolimit') && 'hidden'}`}>
                    {translations[language].tariffes.tariffSmartText}
                    <button onClick={handleMore} className="smart-toggle__desc-text-underline">
                        {translations[language].tariffes.tariffSmartMore}
                    </button>
                </div> */}
                <button onClick={handleMore} className={`smart-toggle__desc-text-underline ${isChecked && currentClass === 'nolimit' && 'nolimit'} ${isChecked && currentClass === 'fit' && 'fit'} ${isChecked && currentClass === 'own' && 'own'} ${(currentClass !== 'own' && currentClass !== 'nolimit' && currentClass !== 'fit') && 'hidden'}`}>
                        {translations[language].tariffes.tariffSmartMore}
                </button>
            </div>
            <motion.div
                className={`smart-toggle__motion ${isChecked ? currentClass : ''}`}
              
                onClick={handleCheck}>
                <motion.div
                className="smart-toggle__motion-btn"
                variants={toggleVariants}
                animate={isChecked ? "smart" : 'nosmart'}
                transition={{duration: 0.2}}
                />
            </motion.div>
        </div>
    )
}

export default SmartToggle;