import React from 'react';
import './Help.css';
import BackButton from '../BackButton/BackButton';
import MenuButton from '../MenuButton/MenuButton';
import valuesIcon from '../../images/values.png';
import possibilitiesIcon from '../../images/possibilities.png';
import newsIcon from '../../images/news.png';
import loveSmileIcon from '../../images/love-smile-min.png';
import faqIcon from '../../images/faq.png';
import noResponceIcon from '../../images/noresponce.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { directionVariants } from '../../utils/directionOptions';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import { parseTimestamp } from '../../utils/helpers';
import { translations } from '../../utils/translations/translations';

function Help() {
  const navigate = useNavigate();
  const direction = useSelector((state) => state.direction);
  const language = useSelector(state => state.language);
  const currentUser = useSelector((state) => state.currentUser);
  return (
    <motion.section
      className='help'
      initial={direction ? 'fromLeft' : 'fromRight'}
      animate={{ x: 0, opacity: 1, transition: { duration: 0.2, delay: 0.2 } }}
      exit={direction ? 'exitToRight' : 'exitToLeft'}
      variants={directionVariants}
    >
      <BurgerMenu color='var(--blue)' />
      <BackButton
        path='/'
        text={translations[language].appButton.mainMenu}
        currentClass='wide'
        title={translations[language].burgerMenu.menuBtn3}
      />
      <div className='help__content'>
        <MenuButton
          handler={() => navigate('/values')}
          image={valuesIcon}
          currentClass='btn-our-values'
          title={translations[language].textTips.ourValues}
          text={translations[language].textTips.valuesText}
          addText={null}
        />
        <MenuButton
          handler={() => navigate('/possibilities')}
          image={possibilitiesIcon}
          currentClass='btn-our-possibilities'
          title={translations[language].textTips.possibilities}
          text={translations[language].textTips.possibilitiesText}
        />
        <div className='help__button-box'>
          {/* <MenuButton
            handler={() => navigate('/news')}
            image={newsIcon}
            currentClass='btn-news'
            title={translations[language].textTips.news}
            text={translations[language].textTips.newsText}
          /> */}
            <MenuButton
            handler={() => navigate('/referral')}
            image={loveSmileIcon}
            currentClass='btn-news'
            title={translations[language].textTips.referral}
            text={translations[language].textTips.referralBtnText}
            addText={null}
          />
          <div className='help__button-news'></div>
          <MenuButton
            handler={() => navigate('/faq')}
            image={faqIcon}
            currentClass='btn-faq'
            title={translations[language].textTips.faq}
            text={translations[language].textTips.faqText}
          />
          <MenuButton
            //handler={() => navigate('/message-us')}
            handler={() => (window.location.href = 'https://t.me/getrobovpnBot')}
            image={noResponceIcon}
            currentClass='btn-no-responce'
            title={translations[language].textTips.noResponce}
            text={translations[language].textTips.noResponceText}
          />
        </div>
        <MenuButton
        image={valuesIcon}
        currentClass='btn-my-tariff'
        title={translations[language].subscription.myTariff}
        text={translations[language].textTips.tariff + ((/^\d+$/).test(currentUser.tariff) ? 'FIT' :currentUser.tariff.replace(/[^a-zA-Z]+/g, '').toUpperCase()) + (currentUser.smart === 1 ? translations[language].tariffes.smart : '')}
        addText={
          currentUser.tariff === 'NOLIMIT'
            ? translations[language].textTips.activeUntil +
              parseTimestamp(currentUser.endActiveDate)
            : translations[language].textTips.left +
              (currentUser.smart === 1 ? currentUser.smartTraffic : (parseInt(currentUser.regularTraffic) + parseInt(currentUser.referralTraffic))) +
              translations[language].textTips.gb
        }
       
        handler={() => navigate('/subscription')}
      />
      </div>
    </motion.section>
  );
}

export default Help;
