import React, { useState } from "react";
import "./Referral.css";
import Popup from "../Popup/Popup";
import BackButton from "../BackButton/BackButton";
import AppButton from "../AppButton/AppButton";
import CopyToClipboardField from "../CopyToClipboardField/CopyToClipboardField";
import { useSelector } from "react-redux";
import ReferralPersonLogo from "../../images/referral_person.svg";
import InviteLogo from "../../images/referral_invite.svg";
import CloseInviteLogo from "../../images/referral_close.svg";
import LadderLogo from "../../images/referral_ladder.svg";
import HeartLogo from "../../images/referral_heart.svg";
import { motion } from "framer-motion";
import { directionVariants } from "../../utils/directionOptions";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import { translations } from "../../utils/translations/translations";
import { getRefTraffic } from "../../utils/helpers";

function NewlineText(props) {
  const text = props.text;
  const newText = text
    .split("\n")
    .map((str) => <p className="referral_term_line">{str}</p>);

  return newText;
}

function Referral() {
  const [isReferPopupHidden, setIsReferPopupHidden] = React.useState(true);
  const currentUser = useSelector((state) => state.currentUser);
  const language = useSelector((state) => state.language);
  const direction = useSelector((state) => state.direction);
  const [isInviteBlockVisible, setIsInviteBlockVisible] = useState(false);
  const activeReferralsNumber = currentUser.refUsers;
  const allReferralsNumber = currentUser.allRefUsers; // FIXME: использовать переменную для отображения общего кол-ва пользователей

  const toggleExpandInvite = () => {
    setIsInviteBlockVisible(!isInviteBlockVisible);
  };

  const renderInviteBlock = () => {
    return (
      <div
        className={`referral_invite_block ${
          (isInviteBlockVisible && "referral_invite_block_visible") ||
          "referral_invite_block_hidden"
        }`}
      >
        <span className="referral_invite_title">
          {translations[language].referral.refTipCopyLink}
        </span>
        <div
          onClick={() => toggleExpandInvite()}
          className="referral_close_invite_button"
        >
          <img className={`referral_close_logo`} src={CloseInviteLogo} />
        </div>
        <CopyToClipboardField
          currentClass="referral__copy-to-clipboard"
          data={currentUser.referralLink}
          gaAction="Referral"
        />
      </div>
    );
  };

  const renderHintsBlock = () => {
    return (
      <>
        <div className="referral_hint_block">
          <span className="referral_hint_title">${currentUser.refBalance}</span>
          <span className="referral_hint_description">
            {translations[language].textTips.balance}
          </span>
          <progress
            id="referralProgress"
            className="referral_progress"
            max="100"
            value={(currentUser.refBalance / 35) * 100}
          >
            70%
          </progress>
          <span className="referral_hint_detail">
            {translations[language].textTips.withdrawThreshold}
          </span>
        </div>
        <div className="referral_hint_block">
          <div className="referral_hint_title_block">
            <span className="referral_hint_title">{activeReferralsNumber}</span>
            {allReferralsNumber && (
              <span className="referral_hint_title_small">
                из {allReferralsNumber || 5}
              </span>
            )}
          </div>
          <span className="referral_hint_description">
            {translations[language].textTips.friendsReferred}
          </span>
        </div>
        <div
          className="referral_hint_block_button"
          onClick={() => toggleExpandInvite()}
        >
          <img className={`referral_invite_logo`} src={InviteLogo} />
          <span className="referral_hint_description_button">
            {translations[language].appButton.inviteFriend}
          </span>
        </div>
      </>
    );
  };

  const handleWithdraw = () => {
    if (currentUser.refBalance < 35) return;
    window.location.href = "https://t.me/getrobovpnBot";
  };

  return (
    <motion.section
      className="referral"
      initial={direction ? "fromLeft" : "fromRight"}
      animate={{ x: 0, opacity: 1, transition: { duration: 0.2, delay: 0.2 } }}
      exit={direction ? "exitToRight" : "exitToLeft"}
      variants={directionVariants}
    >
      {/* <BurgerMenu color='var(--white)' /> */}

      <div className="referral__content">
        <BackButton
          text={translations[language].remote.remoteBtn1}
          path={-1}
          currentClass="white"
          title={translations[language].textTips.referral}
        />
        {/* <div className='referral__text-box'>
          <h1 className='referral__title'>
            {translations[language].referral.refTitle1}
            <br />
            {translations[language].referral.refTitle2}
          </h1>
          <p className='referral__text'>
            {translations[language].referral.refSubtitle}
          </p>
        </div> */}
        <div className="referral_block">
          {renderHintsBlock()}
          {true && renderInviteBlock()}
          {/* <div className='referral_hint_block'>
            <span className='referral_hint_title'>$17</span>
            <span className='referral_hint_description'>{translations[language].textTips.balance}</span>
            <progress id="referralProgress" className='referral_progress' max="100" value="65">70%</progress>
            <span className='referral_hint_detail'>{translations[language].textTips.withdrawThreshold}</span>
          </div>
          <div className='referral_hint_block'>
            <span className='referral_hint_title'>9</span>
            <span className='referral_hint_description'>{translations[language].textTips.friendsReferred}</span>
          </div>
          <div className='referral_hint_block_button' onClick={() => toggleExpandInvite()}>
            <img
              className={`referral_invite_logo`}
              src={InviteLogo}
            />
            <span className='referral_hint_description_button'>{translations[language].appButton.inviteFriend}</span>
          </div> */}
        </div>
        <div className="referral_stages_block">
          <div className="referral_stages_ladder_block">
            {/* <img
              className={`referral_ladder_logo`}
              src={LadderLogo}
            />
            <span className='referral_stages_one_amount'>$1</span>
            <span className='referral_stages_two_amount'>$2</span>
            <span className='referral_stages_three_amount'>$3</span> */}
            <div className="referral_stages_grid_row">
              <div className="referral_stages_grid_cell"></div>
              <div className="referral_stages_grid_cell"></div>
              <div className="referral_stages_grid_cell cell_bottom_center_align">
                <span
                  className={`referral_stages_amount ${
                    activeReferralsNumber >= 7 && "active_person_logo"
                  }`}
                >
                  $5
                </span>
              </div>
            </div>
            <div className="referral_stages_grid_row">
              <div className="referral_stages_grid_cell"></div>
              <div className="referral_stages_grid_cell right_border cell_bottom_center_align">
                <span
                  className={`referral_stages_amount ${
                    activeReferralsNumber >= 4 && "active_person_logo"
                  }`}
                >
                  $3
                </span>
              </div>
              <div className="referral_stages_grid_cell cell_top_center_align top_border">
                <img
                  className={`referral_person_logo ${
                    activeReferralsNumber >= 7 && "active_person_logo"
                  }`}
                  src={ReferralPersonLogo}
                />
                <img
                  className={`referral_person_logo ${
                    activeReferralsNumber >= 8 && "active_person_logo"
                  }`}
                  src={ReferralPersonLogo}
                />
                <img
                  className={`referral_person_logo ${
                    activeReferralsNumber >= 9 && "active_person_logo"
                  }`}
                  src={ReferralPersonLogo}
                />
              </div>
            </div>
            <div className="referral_stages_grid_row">
              <div className="referral_stages_grid_cell bottom_down_border cell_bottom_center_align">
                <span
                  className={`referral_stages_amount ${
                    activeReferralsNumber >= 0 && "active_person_logo"
                  }`}
                >
                  $1
                </span>
              </div>
              <div className="referral_stages_grid_cell cell_top_center_align top_border">
                <img
                  className={`referral_person_logo ${
                    activeReferralsNumber >= 4 && "active_person_logo"
                  }`}
                  src={ReferralPersonLogo}
                />
                <img
                  className={`referral_person_logo ${
                    activeReferralsNumber >= 5 && "active_person_logo"
                  }`}
                  src={ReferralPersonLogo}
                />
                <img
                  className={`referral_person_logo ${
                    activeReferralsNumber >= 6 && "active_person_logo"
                  }`}
                  src={ReferralPersonLogo}
                />
              </div>
              <div className="referral_stages_grid_cell"></div>
            </div>
            <div className="referral_stages_grid_row">
              <div className="referral_stages_grid_cell cell_top_center_align">
                <img
                  className={`referral_person_logo ${
                    activeReferralsNumber >= 1 && "active_person_logo"
                  }`}
                  src={ReferralPersonLogo}
                />
                <img
                  className={`referral_person_logo ${
                    activeReferralsNumber >= 2 && "active_person_logo"
                  }`}
                  src={ReferralPersonLogo}
                />
                <img
                  className={`referral_person_logo ${
                    activeReferralsNumber >= 3 && "active_person_logo"
                  }`}
                  src={ReferralPersonLogo}
                />
              </div>
              <div className="referral_stages_grid_cell"></div>
              <div className="referral_stages_grid_cell"></div>
            </div>
          </div>
          <div className="referral_stages_text_block">
            <span className="referral_stages_text">
              {translations[language].textTips.referralStages}
            </span>
          </div>
        </div>
        <div className="referral_terms_block">
          <div className="referral_terms_fields">
            <NewlineText text={translations[language].textTips.referralTerms} />
          </div>
          <img className={`referral_heart_logo`} src={HeartLogo} />
          {/* <span className='referral_terms_text'>{termsText}</span> */}
        </div>
        {/* <div className='referral__stats'>
          <div className='referral__stat referral__stat_small'>
            <span className='referral__stat-value'>{currentUser.refUsers}</span>
            <p className='referral__stat-text'>
              {translations[language].referral.refWidgetInvitedUsers}
            </p>
          </div>
          <div className='referral__stat referral__stat_small'>
            <span className='referral__stat-value'>
              {currentUser.refUsers * 5 + translations[language].textTips.gb}
            </span>
            <p className='referral__stat-text'>
              {translations[language].referral.refWidgetTraffic2}
            </p>
          </div>
          <div className='referral__stat referral__stat_big'>
            {/* <span className='referral__stat-value'>
              {currentUser.refUsers * 5 + translations[language].textTips.gb}
            </span> 
            <p className='referral__stat-text'>
              {translations[language].referral.refBottom}
            </p>
          </div> 
        </div>*/}
        {/* <div className='referral__temp'>
          <p className='referral__temp-text'>Функция временно на ремонте 🫡</p>
        </div> */}
        {/* <div className='referral__button-container'>
          <p className='referral__tips'>
            {translations[language].referral.refTipCopyLink}
          </p>
          <CopyToClipboardField
            currentClass='referral__copy-to-clipboard'
            data={currentUser.referralLink}
            gaAction='Referral'
          />
          <p className='referral__tips'>
            {translations[language].referral.refTipUserTerms}
          </p>
        </div> */}
      </div>
      <AppButton
        handler={() => handleWithdraw()}
        text={translations[language].textTips.referralButton}
        currentClass="app-button white referral_withdraw_button"
      />
      <Popup
        title={translations[language].textTips.termsAndConditions}
        currentClass="popup-referral"
        isHidden={isReferPopupHidden}
        handleHide={setIsReferPopupHidden}
      >
        {
          <>
            <div className="referral__popup_block">
              <p className="referral__popup-title">
                {translations[language].referral.refTitle1}
              </p>
              <p className="referral__popup-text">
                {translations[language].referral.refText1}
              </p>
              <p className="referral__popup-title">
                {translations[language].referral.refTitle2}
              </p>
              <p className="referral__popup-text">
                {translations[language].referral.refText2}
              </p>
              <p className="referral__popup-title">
                {translations[language].referral.refTitle3}
              </p>
              <p className="referral__popup-text">
                {translations[language].referral.refText3}
              </p>
              <p className="referral__popup-title">
                {translations[language].referral.refTitle4}
              </p>
              <p className="referral__popup-text">
                {translations[language].referral.refText4}
              </p>
              <p className="referral__popup-title">
                {translations[language].referral.refTitle5}
              </p>
              <p className="referral__popup-text">
                {translations[language].referral.refText5}
              </p>
              <p className="referral__popup-title">
                {translations[language].referral.refTitle6}
              </p>
              <p className="referral__popup-text">
                {translations[language].referral.refText6}
              </p>
              <p className="referral__popup-title">
                {translations[language].referral.refTitle7}
              </p>
              <p className="referral__popup-text">
                {translations[language].referral.refText7}
              </p>
              <p className="referral__popup-title">
                {translations[language].referral.refTitle8}
              </p>
              <p className="referral__popup-text">
                {translations[language].referral.refText8}
              </p>
              <p className="referral__popup-title">
                {translations[language].referral.refTitle9}
              </p>
              <p className="referral__popup-text">
                {translations[language].referral.refText9}
              </p>
            </div>
            <AppButton
              text={translations[language].appButton.gotIt}
              currentClass="primary white margin-top bg-violet"
              handler={() => setIsReferPopupHidden(true)}
            />
          </>
        }
      </Popup>
    </motion.section>
  );
}

export default Referral;
