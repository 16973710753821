export function parseTimestamp(timestamp) {
  const date = new Date(timestamp);
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
}

export const getProfitValue = (main, secondary, count) =>
  main * count - secondary * count;

export function getTotalDiscountedValue(plan, months, userDisc) {
  if (userDisc) {
    return Math.round(plan * months - ((plan * months) / 100) * userDisc);
  } else {
    return Math.round(plan * months);
  }
}

export const getBonus = (bonus) => {
  return (bonus === null || bonus === '' || bonus === 0) ? 0 : parseInt(bonus.replace(/[^0-9]/g), '');
}

export const roundTraffic = (traffic) => {
  return (Math.round(traffic * 10) / 10)
}

export const getRefTraffic = (ref) => {
  return ((ref / 1073741824).toFixed(1))
}

export const getDiscountedPrice = (price, discount) => {
  return Math.round(price * (100 - parseInt(discount)) / 100)
}

export const getNextGb = (nextTariff) => {
  return nextTariff !== null && nextTariff !== 0 && nextTariff !== '' && nextTariff.replace(/[^a-zA-Z]+/g, '').toLowerCase() !== 'nolimit' 
    ? parseInt(nextTariff.replace(/\D/g, '')) : 0
}

export const getIsFree = (user) => {
  const today = new Date();
  return ((/^\d+$/).test(user.tariff)
  && (user.smartTraffic <= 0 && user.referralTraffic <= 0 && user.regularTraffic <= 0)
  || ((/^\d+$/).test(user.tariff)  && (user.smartTraffic <= 0 && user.regularTraffic <= 0 && user.referralTraffic > 0) ))
  || (user.tariff.replace(/[^a-zA-Z]+/g, '').toLowerCase() === 'nolimit' && user.endActiveDate < today && user.referralTraffic > 0)
}

export const getTariffEnded = (user, isFree) => {
  const today = new Date();
  return (((/^\d+$/).test(user.tariff)  
  && (user.smartTraffic <= 0 
    && user.regularTraffic <= 0 
    && user.referralTraffic <= 0 
    ))
  || ((/^\d+$/).test(user.tariff) && user.endDate < today)
  || (isFree && user.regularTraffic <=0 && user.smartTraffic <= 0 && user.referralTraffic <= 0)
  || (user.tariff.replace(/[^a-zA-Z]+/g, '').toLowerCase() === 'nolimit' 
      && (user.endActiveDate < today)
      && user.referralTraffic <= 0));
}

export const getOnlyOwn = (user, endedFunc, remoteData) => {
  const today = new Date();
  return endedFunc && user.owner === 1 && remoteData > today
}

export const getNextOwn = (user, endedFunc, remoteData) => {
  const today = new Date();
  return !endedFunc && user.owner === 1 && remoteData > today
}

