import { bindActionCreators } from 'redux';
import { dispatch } from '../store/store';

const setCurrentUserAction = (payload) => {
  return {
    type: 'USER/SET',
    payload,
  };
};
const setPaymentAction = (payload) => {
  return {
    type: 'PAYMENT/SET',
    payload,
  };
};
const setPaymentUrlAction = (payload) => {
  return {
    type: 'PAYMENT_URL/SET',
    payload,
  };
};
const setPricesAction = (payload) => {
  return {
    type: 'PRICES/SET',
    payload,
  };
};
const setDirectionAction = (payload) => {
  return {
    type: 'DIRECTION/SET',
    payload,
  };
};
const setCurrentCountryAction = (payload) => {
  return {
    type: 'COUNTRY/SET',
    payload,
  };
};
const setNextTariffAction = (payload) => {
  return {
    type: 'TARIFF/SET',
    payload,
  };
};
const setRedirectAction = (payload) => {
  return {
    type: 'REDIRECT/SET',
    payload,
  };
};

const setPaymentSmartAction = (payload) => {
  return {
    type: 'SMART/SET',
    payload,
  };
};

const setPaymentTestAction = (payload) => {
  return {
    type: 'TEST/SET',
    payload
  };
}

const setOwnCountryAction = (payload) => {
  return {
    type: 'OWNCOUNTRY/SET',
    payload
  };
}

const setRemoteDataAction = (payload) => {
  return {
    type: 'REMOTEDATA/SET',
    payload
  };
}

const setRemoteHenchmensAction = (payload) => {
  return {
      type: 'REMOTEHENCHMENS/SET',
      payload
  }
}

const setLanguageAction = (payload) => {
  return {
      type: 'LANGUAGE/SET',
      payload
  }
}

export const {
  setCurrentUser,
  setPayment,
  setPrices,
  setDirection,
  setCurrentCountry,
  setPaymentUrl,
  setNextTariff,
  setRedirect,
  setPaymentSmart,
  setPaymentTest,
  setOwnCountry,
  setRemoteData,
  setRemoteHenchmens,
  setLanguage
} = bindActionCreators(
  {
    setCurrentUser: setCurrentUserAction,
    setPayment: setPaymentAction,
    setPrices: setPricesAction,
    setDirection: setDirectionAction,
    setCurrentCountry: setCurrentCountryAction,
    setPaymentUrl: setPaymentUrlAction,
    setNextTariff: setNextTariffAction,
    setRedirect: setRedirectAction,
    setPaymentSmart: setPaymentSmartAction,
    setPaymentTest: setPaymentTestAction,
    setOwnCountry: setCurrentCountryAction,
    setRemoteData: setRemoteDataAction,
    setRemoteHenchmens: setRemoteHenchmensAction,
    setLanguage: setLanguageAction
  },
  dispatch
);
