import React from 'react';
import './Payment.css';
import BackButton from '../BackButton/BackButton';
import PaymentsEmailForm from '../PaymentsEmailForm/PaymentsEmailForm';
import { useSelector } from 'react-redux';
import FormLabel from '../FormLabel/FormLabel';
import AppButton from '../AppButton/AppButton';
import { getPaymentLink, payWithBalance, getPaymentLinkOwn } from '../../utils/roboApi';
import { setCurrentUser, setDirection, setPaymentUrl } from '../../redux/actions/actions';
import { motion } from 'framer-motion';
import { directionVariants } from '../../utils/directionOptions';
import useAnalyticsEventTracker from '../../hooks/useAnanlyticsEventTracker';
import { useNavigate, useLocation } from 'react-router-dom';
import { translations } from '../../utils/translations/translations';
import PreloaderOnRequest from '../PreloaderOnRequest/PreloaderOnRequest';

function Payment() {
  const payment = useSelector((state) => state.payment);
  const currentUser = useSelector((state) => state.currentUser);
  const language = useSelector(state => state.language);
  const direction = useSelector((state) => state.direction);
  const paymentUrl = useSelector((state) => state.paymentUrl);
  const paymentSmart = useSelector((state) => state.isPaymentSmart);
  const nextTariff = useSelector((state) => state.nextTariff);
  const ownCountry = useSelector((state) => state.currentCountry);
  const [withBalance, setWithBalance] = React.useState(false);

  // FIXME: нужно использовать значение из чекбокса `robo_balance_check`, он в state?
  //const withRoboBalance = useSelector((state) => state.withRoboBalance);

  const [method, setMethod] = React.useState('');
  const [isLoaded, setIsLoaded] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const gaEventTracker = useAnalyticsEventTracker('payment');
  const isBalance = React.useMemo(() => currentUser.balance <= 0, []);
  const usedDiscount = React.useMemo(() => (currentUser.discount === null || currentUser.discount === 0) ? 0 : 1, [])

  function handlePay() {
    setIsLoaded(true);
    const balanceInUse = withBalance ? 1 : 0;
    const roboBalanceInUse = location.state && location.state.isWithRoboBalance ? 1 : 0;
    gaEventTracker('click', 'payment button click');
    
    if (withBalance && currentUser.balance > payment) {
      payWithBalance(currentUser.userId, payment.toString(), nextTariff)
        .then((res) => {
          setIsLoaded(false);
          setCurrentUser(res);
          navigate(paymentUrl);
          setPaymentUrl('success');
        })
        .catch(() => navigate('/error'));
    } else {
      if(nextTariff.substring(0, 3) === 'own') {
        getPaymentLinkOwn(
          currentUser.userId,
          payment.toString(),
          paymentUrl,
          balanceInUse,
          nextTariff === '' ? 'balance' : nextTariff,
          paymentSmart,
          usedDiscount,
          ownCountry
        )
        .then((res) => {
          window.location.href = res;
           setPaymentUrl('success');
          setIsLoaded(false);
           console.log(res);
         })
         .catch(() => navigate('/error'));

      } else {
        getPaymentLink(
          currentUser.userId,
          payment.toString(),
          paymentUrl,
          balanceInUse,
          roboBalanceInUse,
          nextTariff === '' ? 'balance' : nextTariff,
          paymentSmart,
          usedDiscount
        )
          .then((res) => {
           window.location.href = res;
            setPaymentUrl('success');
           setIsLoaded(false);
            console.log(res);
          })
          .catch(() => navigate('/error'));
      }
     
    }
    
    
  }

  const memoHandlePay = React.useMemo(() => handlePay(), [])
  return (
    <motion.section
      className='payment'
      initial={direction ? 'fromLeft' : 'fromRight'}
      animate={{ x: 0, opacity: 1, transition: { duration: 0.2, delay: 0.2 } }}
      exit={direction ? 'exitToRight' : 'exitToLeft'}
      variants={directionVariants}
    >
      <BackButton
        path={-1}
        text=''
        title={translations[language].textTips.paymentOptions}
        currentClass='wide'
      />
      {/* {!currentUser.email && !currentUser.email.length ? (
        <PaymentsEmailForm />
      ) : ( */}
        <>
          {isLoaded ? (
            <PreloaderOnRequest />
          ) : (
          
            isBalance ? memoHandlePay
              :
            <>
              <div className='payment__methods'>
                <FormLabel
                  name='method'
                  currentClass='form-label__method'
                  title={translations[language].payment.paymentCardTitle}
                  text={translations[language].payment.paymentCardText}
                  elementValue='card'
                  handler={(data) => setMethod(data)}
                  defaultChecked={true}
                />
                <FormLabel
                  name='method'
                  currentClass='form-label__method disabled'
                  title={translations[language].payment.paymentCryptoTitle}
                  text={translations[language].payment.paymentCryptoText}
                  elementValue='crypto'
                  handler={(data) => setMethod(data)}
                  disabled={true}
                />
                <motion.label
                  className='form-label__method checkbox'
                  whileTap={{ scale: 0.95 }}
                >
                  <span className='form-label__title form-label__title_moved'>
                    {translations[language].payment.paymentBalanceTitle}
                  </span>
                  <span className='form-label__text-secondary'>
                    {translations[language].payment.paymentBalanceText +
                      currentUser.balance +
                      translations[language].textTips.currency}
                  </span>
                  <input
                    className='form-label__radio-input checkbox'
                    type='checkbox'
                    checked={withBalance}
                    onChange={() => setWithBalance((state) => !state)}
                  />
                </motion.label>
              </div>
              <div className='payment__button-box'>
                <div className='payment__value'>
                  <span className='payment__value-title'>
                    {translations[language].payment.toPay}
                  </span>
                  <span className='payment__value-data'>
                    {withBalance
                      ? currentUser.balance > payment
                        ? 0 + translations[language].textTips.currency
                        : payment -
                          currentUser.balance +
                          translations[language].textTips.currency
                      : payment + translations[language].textTips.currency}
                  </span>
                </div>
                <AppButton
                  currentClass='primary white bg-dark-blue margin-top'
                  text={translations[language].appButton.pay}
                  handler={handlePay}
                />
              </div>
            </>
            
          )}
        </>
      {/* )} */}
    </motion.section>
  );
}

export default Payment;
