import React from "react";
import "./Main.css";
import MenuButton from "../MenuButton/MenuButton";
import glassesIcon from "../../images/smile-min.png";
import moneyIcon from "../../images/winged-money-min.png";
import loveSmileIcon from "../../images/love-smile-min.png";
import letterIcon from "../../images/letter-min.png";
import weirdSmileIcon from "../../images/look-up-smile-min.png";
import referralIcon from "../../images/referral.png";
import robotIcon from "../../images/robot-min.png";
import remoteIcon from "../../images/remote_icon.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { directionVariants } from "../../utils/directionOptions";
import {
  parseTimestamp,
  getBonus,
  roundTraffic,
  getRefTraffic,
  getIsFree,
  getTariffEnded,
  getOnlyOwn,
  getNextGb,
} from "../../utils/helpers";
import { translations } from "../../utils/translations/translations";

function Main() {
  const currentUser = useSelector((state) => state.currentUser);
  const remoteData = useSelector((state) => state.remoteData);
  // 1678368993 - закончился
  // const currentUser = {
  //   tariff: '20',
  //   endDate: 1678368993 * 1000,
  //   bonusDate: 1677921307591.322,
  //   smart: 0,
  //   smartTraffic: 0,
  //   regularTraffic: 0,
  //   bonusTrafficNow: '',
  //   bonusTrafficNextMonth: '5',
  //   referralTraffic: 0,
  //   owner: 1
  // }
  const direction = useSelector((state) => state.direction);
  const navigate = useNavigate();
  const today = new Date();
  const isFree = getIsFree(currentUser, getBonus);
  const tariffEnded = getTariffEnded(currentUser, getBonus, isFree);
  const onlyOwn = getOnlyOwn(
    currentUser,
    tariffEnded,
    remoteData?.data?.endDate
  );
  const nextFit = getNextGb(currentUser.firstFitTariff);
  const tariffType = remoteData?.data?.tariff?.replace(/[^a-zA-Z]/g, "");
  const language = useSelector((state) => state.language);

  const getOwnTariffTypeGb = () => {
    if (tariffType === "ownme" || tariffType === "ownme__test") {
      return 500;
    }
    if (tariffType === "ownfam" || tariffType === "ownfam__test") {
      return 1000;
    }

    if (tariffType === "owncom" || tariffType === "owncom__test") {
      return 2000;
    }
  };

  const isAvailableTraffic =
    isFree &&
    (currentUser.regularTraffic > 0 ||
      currentUser.smartTraffic > 0 ||
      currentUser.referralTraffic > 0);
  const isBonusTrafficOver =
    !onlyOwn &&
    ((/^\d+$/.test(currentUser.tariff) &&
      currentUser.smartTraffic <= 0 &&
      currentUser.referralTraffic <= 0 &&
      currentUser.regularTraffic <= 0 &&
      getBonus(currentUser.bonusTrafficNow) === 0) ||
      (/^\d+$/.test(currentUser.tariff) &&
        currentUser.endDate < today &&
        currentUser.referralTraffic < 0) ||
      (isFree &&
        currentUser.regularTraffic <= 0 &&
        currentUser.smartTraffic <= 0 &&
        currentUser.referralTraffic <= 0) ||
      (currentUser.tariff.replace(/[^a-zA-Z]+/g, "").toLowerCase() ===
        "nolimit" &&
        currentUser.endActiveDate < today &&
        currentUser.referralTraffic <= 0));

  return (
    <motion.section
      className="main"
      initial={direction ? "fromLeft" : "fromRight"}
      animate={{ x: 0, opacity: 1, transition: { duration: 0.2, delay: 0.2 } }}
      exit={direction ? "exitToRight" : "exitToLeft"}
      variants={directionVariants}
    >
      <div className="main__button-container">
        <MenuButton
          handler={() => navigate("/my-vpn")}
          image={glassesIcon}
          currentClass="btn-my-vpn"
          title={translations[language].textTips.myVpn}
          text={
            translations[language].textTips.tariff +
            (!onlyOwn && /^\d+$/.test(currentUser.tariff)
              ? "FIT"
              : onlyOwn
              ? "OWN"
              : currentUser.tariff.replace(/[^a-zA-Z]+/g, "").toUpperCase()) +
            (currentUser.referralTraffic + currentUser.smartTraffic >=
            currentUser.regularTraffic
              ? translations[language].tariffes.smart
              : "") +
            (tariffEnded && !onlyOwn
              ? translations[language].tariffes.tariffEnded
              : "")
          }
          addText={
            isAvailableTraffic
              ? translations[language].textTips.left +
                getRefTraffic(currentUser.referralTraffic) +
                translations[language].textTips.gb
              : isBonusTrafficOver
              ? translations[language].textTips.freeGb +
                parseTimestamp(currentUser.bonusDate)
              : (currentUser.tariff.replace(/[^a-zA-Z]+/g, "").toLowerCase() ===
                  "nolimit" &&
                  currentUser.endActiveDate > today) ||
                onlyOwn
              ? translations[language].textTips.activeUntil +
                (onlyOwn
                  ? parseTimestamp(remoteData?.data?.endDate)
                  : parseTimestamp(currentUser.endActiveDate))
              : translations[language].textTips.left +
                (
                  currentUser.smartTraffic +
                  currentUser.regularTraffic +
                  parseInt(getRefTraffic(currentUser.referralTraffic)) +
                  nextFit
                ).toFixed(1) +
                translations[language].textTips.gb +
                (isFree
                  ? ""
                  : translations[language].textTips.till +
                    parseTimestamp(currentUser.endDate))
          }
          //    bonusFitText={
          //     isFree && currentUser.bonusTrafficNextMonth <= 0
          //       ? translations[language].tariffes.getPay + parseTimestamp(currentUser.bonusDate) + translations[language].tariffes.getPay2
          //       : ''
          // }
        />
        <MenuButton
          handler={() => navigate("/referral")}
          image={referralIcon}
          currentClass="btn-referrals"
          title={translations[language].textTips.referral}
          text={translations[language].textTips.referralBtnText}
          addText={null}
        />
        <MenuButton
          handler={() => navigate("/instruction")}
          image={robotIcon}
          currentClass="btn-robot"
          title={translations[language].textTips.instructionBtn}
          text={translations[language].textTips.instructionsBtnText}
          addText={null}
        />
        <MenuButton
          handler={() => navigate("/help")}
          image={weirdSmileIcon}
          currentClass="btn-not-understand"
          title={translations[language].textTips.notUnderstand}
          text={translations[language].textTips.notUnderstandText}
          addText={null}
        />
        <MenuButton
          handler={() => navigate("/remote")}
          image={remoteIcon}
          currentClass="btn-gift"
          title={translations[language].textTips.remote}
          text={
            remoteData?.data
              ? remoteData?.data?.tariff?.includes("_test")
                ? translations[language].tariffOwn.ownWidget1
                : translations[language].tariffOwn.ownWidget1 +
                  "," +
                  translations[language].tariffOwn.ownWidget5 +
                  parseTimestamp(remoteData?.data?.endDate)
              : currentUser.owner === 1
              ? translations[language].remote.remoteDeveloping
              : translations[language].textTips.remoteMenuText
          }
          addText={
            remoteData?.data
              ? currentUser.owner === 0
                ? null
                : remoteData?.data?.tariff?.includes("_test")
                ? remoteData?.data?.endDate >= today
                  ? translations[language].tariffOwn.ownWidget2 +
                    translations[language].tariffOwn.ownWidget3
                  : translations[language].tariffOwn.ownWidget4
                : remoteData?.data?.trafficTotal > 0
                ? translations[language].tariffOwn.ownWidget7 +
                  (remoteData?.data?.nextGB
                    ? parseTimestamp(remoteData?.data?.nextGB)
                    : parseTimestamp(remoteData?.data?.endDate)) +
                  translations[language].tariffOwn.ownWidget8 +
                  remoteData?.data?.trafficTotal +
                  translations[language].textTips.gb
                : translations[language].tariffOwn.ownWidget9 +
                  getOwnTariffTypeGb() +
                  translations[language].textTips.gb +
                  " " +
                  (remoteData?.data?.nextGB
                    ? parseTimestamp(remoteData?.data?.nextGB)
                    : parseTimestamp(remoteData?.data?.endDate))
              : null
          }
        />
      </div>
      <MenuButton
        handler={() => navigate("/tariffes")}
        image={moneyIcon}
        currentClass="btn-tariffes"
        title={translations[language].textTips.tariffes}
        text={translations[language].textTips.tariffesBtnText}
        addText={null}
        LabelComponent={() =>
          currentUser.isFirstRefUser ? (
            <div className="tariffes_btn_discount_label">
              <span className="tariffes_btn_discount_label_text">
                У вас х2 на все 🔥
              </span>
            </div>
          ) : null
        }
      />
    </motion.section>
  );
}

export default Main;
