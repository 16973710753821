import React from 'react';
import './BurgerMenuNav.css';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import roboLogo from '../../images/intro_smile_0.svg';
import { setDirection } from '../../redux/actions/actions';
import { parseTimestamp, getBonus, roundTraffic, getRefTraffic, getIsFree, getTariffEnded, getOnlyOwn, getNextGb } from '../../utils/helpers';
import PropTypes from 'prop-types';
import { translations } from '../../utils/translations/translations';

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};
const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

function BurgerMenuNav({ toggleOpen, setIsActive }) {
  const navigate = useNavigate();
  function handleClose(path) {
    setDirection(true);
    setIsActive(false);
    toggleOpen();
    setTimeout(navigate, 700, path);
  }
const currentUser = useSelector((state) => state.currentUser);
const remoteData = useSelector((state) => state.remoteData);
  // 1678368993 - закончился
  // const currentUser = {
  //   tariff: '20',
  //   endDate: 		1688965125 * 1000,
  //   bonusDate: 1677921307591.322,
  //   smart: 0,
  //   smartTraffic: 104857600,
  //   regularTraffic: 5,
  //   bonusTrafficNow: '5_regular',
  //   bonusTrafficNextMonth: '5',
  //   referralTraffic: 0,
  //   daysLeft: 20
  // }
  const today = new Date();
  const isFree = getIsFree(currentUser, getBonus);
  const language = useSelector(state => state.language);
  const tariffEnded = getTariffEnded(currentUser, getBonus, isFree);
  const onlyOwn = getOnlyOwn(currentUser, tariffEnded, remoteData?.data?.endDate);
  const nextFit = getNextGb(currentUser.firstFitTariff);

  return (
    <motion.ul className='burger-menu-nav' variants={variants}>
      <motion.div variants={itemVariants} className='burger-item__user'>
        <div className='burger-menu-nav__userdata'>
          {/* <div className='burger-menu-nav__current-user'>
            <span>{currentUser.userName}</span>
            <img className='burger-menu-nav__image' src={roboLogo} alt='robo' />
          </div> */}
          <span className='burger-menu-nav__text'>
          {( 
              (translations[language].textTips.tariff + (!onlyOwn && (/^\d+$/).test(currentUser.tariff) ? 'FIT' : (onlyOwn ? 'OWN' : currentUser.tariff.replace(/[^a-zA-Z]+/g, '').toUpperCase())))) + ((currentUser.referralTraffic + currentUser.smartTraffic >= currentUser.regularTraffic) ? translations[language].tariffes.smart : '') + (tariffEnded ? translations[language].tariffes.tariffEnded : '')}
          </span>
          <span className='burger-menu-nav__text'>
          {
          (isFree && (currentUser.regularTraffic > 0 || currentUser.smartTraffic > 0 || currentUser.referralTraffic > 0)) ? 
            translations[language].textTips.left + (getRefTraffic(currentUser.referralTraffic)) + translations[language].textTips.gb
            : (!onlyOwn && (((/^\d+$/).test(currentUser.tariff) 
              && (currentUser.smartTraffic <= 0 && currentUser.referralTraffic <= 0 && currentUser.regularTraffic <= 0 && getBonus(currentUser.bonusTrafficNow) === 0))
              || ((/^\d+$/).test(currentUser.tariff) && (currentUser.endDate < today) && currentUser.referralTraffic < 0)
              || (isFree && currentUser.regularTraffic <=0 && currentUser.smartTraffic <= 0 && currentUser.referralTraffic <= 0)
              || (currentUser.tariff.replace(/[^a-zA-Z]+/g, '').toLowerCase() === 'nolimit' && (currentUser.endActiveDate < today) && currentUser.referralTraffic <= 0)))
          ? (translations[language].textTips.freeGb + parseTimestamp(currentUser.bonusDate))
          
          :
            (currentUser.tariff.replace(/[^a-zA-Z]+/g, '').toLowerCase() === 'nolimit' && currentUser.endActiveDate > today
              || onlyOwn
                ? (translations[language].textTips.activeUntil +
                  (onlyOwn ? parseTimestamp(remoteData?.data?.endDate) : parseTimestamp(currentUser.endActiveDate)))
                : ((translations[language].textTips.left +
                  ((currentUser.smartTraffic + currentUser.regularTraffic + parseInt(getRefTraffic(currentUser.referralTraffic)) + nextFit).toFixed(1)) +
                  translations[language].textTips.gb) + (isFree ? '' : translations[language].textTips.till + parseTimestamp(currentUser.endDate))))
        }
       
          </span>
        </div>
      </motion.div>
      <motion.div variants={itemVariants} className='burger-item'>
        <span className='burger__button-title'>
          {translations[language].burgerMenu.menuTitle}
        </span>
      </motion.div>
      <motion.div variants={itemVariants} className='burger-item'>
        <button
          onClick={() => handleClose('/')}
          className='burger__navigate-button'
        >
          {translations[language].appButton.mainMenu}
          <span className='burger__button-corner' />
        </button>
      </motion.div>
      <motion.div variants={itemVariants} className='burger-item'>
        <button
          onClick={() => handleClose('/my-vpn')}
          className='burger__navigate-button'
        >
          {translations[language].textTips.myVpn}
          <span className='burger__button-corner' />
        </button>
      </motion.div>
      <motion.div variants={itemVariants} className='burger-item'>
        <button
          onClick={() => handleClose('/help')}
          className='burger__navigate-button'
        >
          {translations[language].textTips.notUnderstand}
          <span className='burger__button-corner' />
        </button>
      </motion.div>
      <motion.div variants={itemVariants} className='burger-item'>
        <span className='burger-menu-nav__support-text'>
          {translations[language].burgerMenu.menuFaqText1}
          <br />
            {translations[language].burgerMenu.menuFaqText2}
            <br />
            {translations[language].burgerMenu.menuSupportTip1}
          <br /> 
          {translations[language].burgerMenu.menuSupportTip2}
          {/* <span
            onClick={() => {
              toggleOpen();
              setIsActive(false);
              navigate('/faq');
            }}
            className='burger-menu-nav__link'
          >
            {translations[language].textTips.faq}
          </span> */}
        </span>
      </motion.div>
    </motion.ul>
  );
}

BurgerMenuNav.propTypes = {
  toggleOpen: PropTypes.func.isRequired,
  setIsActive: PropTypes.func.isRequired,
};
export default BurgerMenuNav;
