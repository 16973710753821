import React from "react";
import "./CurrentTariffWidget.css";
import { useSelector } from "react-redux";
import {
  parseTimestamp,
  getBonus,
  roundTraffic,
  getRefTraffic,
  getIsFree,
  getTariffEnded,
  getNextGb,
} from "../../utils/helpers";
import { translations } from "../../utils/translations/translations";

function CurrentTariffWidget({ isTariffesList }) {
  const currentUser = useSelector((state) => state.currentUser);
  const language = useSelector((state) => state.language);
  // 1678368993 - закончился
  // 1679077973
  // const currentUser = {
  //   tariff: '20',
  //   endDate: 		1688965125 * 1000,
  //   bonusDate: 1691241338629.586,
  //   smart: 0,
  //   smartTraffic: 0,
  //   regularTraffic: 0,
  //   bonusTrafficNow: '5_regular',
  //   bonusTrafficNextMonth: '5',
  //   referralTraffic: 0,
  //   daysLeft: 20,
  //   firstFitTariff: 'Fit_3'
  // }
  const today = new Date();
  const isFree = getIsFree(currentUser, getBonus);
  const isEmpty = getTariffEnded(currentUser, getBonus, isFree);
  const nextFit = getNextGb(currentUser.firstFitTariff);

  if (currentUser.isFirstRefUser && isTariffesList) {
    return (
      <span
        className={`current-tariff-widget ${
          isTariffesList && "tariffes-list-widget"
        }`}
      >
        {translations[language].textTips.firstBuyHint}
      </span>
    );
  }

  return (
    <span
      className={`current-tariff-widget ${
        isTariffesList && "tariffes-list-widget"
      }`}
    >
      {translations[language].textTips.yourTariff +
        (/^\d+$/.test(currentUser.tariff) ? "FIT" : "NOLIMIT") +
        (currentUser.referralTraffic + currentUser.smartTraffic >=
        currentUser.regularTraffic
          ? translations[language].tariffes.plus +
            translations[language].tariffes.plusSmart
          : "") +
        (isEmpty && currentUser.referralTraffic <= 0
          ? translations[language].tariffes.tariffEnded
          : "")}
      <br />
      {(/^\d+$/.test(currentUser.tariff) &&
        currentUser.smartTraffic <= 0 &&
        currentUser.regularTraffic <= 0 &&
        currentUser.referralTraffic <= 0 &&
        getBonus(currentUser.bonusTrafficNow) === 0) ||
      (/^\d+$/.test(currentUser.tariff) &&
        currentUser.endDate < today &&
        currentUser.referralTraffic <= 0) ||
      (currentUser.tariff.replace(/[^a-zA-Z]+/g, "").toLowerCase() ===
        "nolimit" &&
        currentUser.endActiveDate < today &&
        currentUser.referralTraffic <= 0 &&
        currentUser.referralTraffic <= 0) ||
      (isFree &&
        currentUser.regularTraffic <= 0 &&
        currentUser.smartTraffic <= 0 &&
        currentUser.referralTraffic <= 0)
        ? translations[language].textTips.freeGb +
          parseTimestamp(currentUser.bonusDate)
        : currentUser.tariff.replace(/[^a-zA-Z]+/g, "").toLowerCase() ===
            "nolimit" && currentUser.endActiveDate > today
        ? translations[language].textTips.activeUntil +
          parseTimestamp(currentUser.endActiveDate)
        : translations[language].textTips.left +
          (
            currentUser.smartTraffic +
            currentUser.regularTraffic +
            parseInt(getRefTraffic(currentUser.referralTraffic)) +
            nextFit
          ).toFixed(1) +
          translations[language].textTips.gb +
          (isFree
            ? ""
            : translations[language].textTips.on +
              currentUser.daysLeft +
              translations[language].textTips.days)}
    </span>
  );
}

export default CurrentTariffWidget;
