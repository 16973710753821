import React from 'react';
import './RemotePopup.css';
import { motion } from 'framer-motion';

const popupVariants = {
    open: { opacity: 1, transition: { duration: 0.2 } },
    closed: { opacity: 0, transition: { duration: 0.2 } },
  };

function RemotePopup(
    {isHidden,
    setIsHidden,
    setDoneShow,
    children,
    buttonText,
    }) {


   

    return(
    <motion.div
      initial='closed'
      animate={isHidden ? 'closed' : 'open'}
      exit='closed'
      variants={popupVariants}
      className={`remote__popup ${!isHidden && 'active'}`}
      onClick={() => {setIsHidden(true); setTimeout(setDoneShow, 300, (state) => state = false)}}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`remote__popup__info`}
      >
        <button
          onClick={() => {setIsHidden(true); setDoneShow(false)}}
          className={`remote__popup__info-button`}
        >
          {buttonText}
          {/* <span
            className={`tariffes-template-popup__info-button-arrow ${currentClass}`}
          /> */}
        </button>
        {children}
      </div>
    </motion.div>
    )
}

export default RemotePopup;