import React from 'react';
import './GiftSuccess.css';
import AppButton from '../AppButton/AppButton';
import { useSelector } from 'react-redux';
import { translations } from '../../utils/translations/translations';

function GiftSuccess() {
  const tg = window.Telegram.WebApp;
  const language = useSelector(state => state.language);
  return (
    <section className='gift-success'>
      {' '}
      <div className='gift-success__content'>
        <h1 className='gift-success__title'>
          {translations[language].gift.giftSuccessTitle1}
          <br /> {translations[language].gift.giftSuccessTitle2}
        </h1>
        <p className='gift-success__text'>
          {translations[language].gift.giftSuccessText}
        </p>
      </div>
      <AppButton
        text={translations[language].appButton.toTelegram}
        handler={() => tg.close()}
        currentClass='orange primary wide'
      />
    </section>
  );
}

export default GiftSuccess;
