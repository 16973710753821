import React from 'react';
import './Instruction.css';
import AppButton from '../AppButton/AppButton';
import androidDownload from '../../images/android_download.svg';
import windowsDownload from '../../images/windows_download.svg';
import chromeDownload from '../../images/chrome_download.svg';
import appleDownload from '../../images/apple_download.svg';
import linuxDownload from '../../images/linux_download.svg';
import chromeDownloadColored from '../../images/chrome_download_colored.svg';
import linuxDownloadColored from '../../images/linux_download_colored.svg';
import macosStepOne from '../../images/foxray_instruction_1.png';
import macosStepTwo from '../../images/foxray_instruction_2.png';
import iPadStepOne from '../../images/streisand_instruction_1.png';
import iPadStepTwo from '../../images/streisand_instruction_2.png';
import iosStepOne from '../../images/streisand_ios_instruction_1.png';
import iosStepTwo from '../../images/streisand_ios_instruction_2.png';
import androidStepOne from '../../images/v2rayNG_instruction_1.png';
import androidStepTwo from '../../images/v2rayNG_instruction_2.png';
import windowsStepOne from '../../images/invisibleman_instruction_1.png';
import windowsStepTwo from '../../images/invisibleman_instruction_2.png';
import windowsStepThree from '../../images/invisibleman_instruction_3.png';
import windowsStepFour from '../../images/invisibleman_instruction_4.png';
import { useSwipeable } from 'react-swipeable';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CopyToClipboardField from '../CopyToClipboardField/CopyToClipboardField';
import { motion } from 'framer-motion';
import { directionVariants } from '../../utils/directionOptions';
import { setDirection } from '../../redux/actions/actions';
import useAnalyticsEventTracker from '../../hooks/useAnanlyticsEventTracker';
import { translations } from '../../utils/translations/translations';
import platform from 'platform-detect';
import formfactor from 'platform-detect/formfactor.mjs'
import { render } from '@testing-library/react';


const variants = {
  visible: { opacity: 1, transition: { duration: 0.2 } },
  faded: { opacity: 0, transition: { duration: 0.1 } },
};

const buttonVariants = {
  visible: { y: 0, opacity: 1, transition: { duration: 0.2 } },
  hidden: {
    y: '120%',
    opacity: 0,
    transition: { duration: 0.1 },
  },
};


function Instruction() {
  const gaEventTracker = useAnalyticsEventTracker('instruction');
  const tg = window.Telegram.WebApp;
  const [progress, setProgress] = React.useState(0);
  const language = useSelector(state => state.language);
  const [isFaded, setIsFaded] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [nextDisabled, setNextDisabled] = React.useState(true);
  const [dowloadLink, setDownloadLink] = React.useState();
  const [chosenPlatform, setChosenPaltform] = React.useState();
  const navigate = useNavigate();
  const direction = useSelector((state) => state.direction);
  const currentUser = useSelector((state) => state.currentUser);
  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
  });

  const softTitles = {
    ios: translations[language].instruction.p3TitleSoftName1,
    iPad: translations[language].instruction.p3TitleSoftName1,
    macos: translations[language].instruction.p3TitleSoftName2,
    android: translations[language].instruction.p3TitleSoftName3,
    windows: translations[language].instruction.p3TitleSoftName4
  }

  React.useEffect(() => {
    isFaded && setTimeout(setIsFaded, 300, false);
    progress > 2 && setProgress(4);
  }, [isFaded, progress]);


  React.useEffect(() => {
    if(platform.ios && !formfactor.tablet) {
      setDownloadLink('https://apps.apple.com/us/app/streisand/id6450534064');
      setChosenPaltform('ios');
    }
    if(platform.ios && formfactor.tablet) {
      setDownloadLink('https://apps.apple.com/us/app/streisand/id6450534064');
      setChosenPaltform('iPad');
    }
    if(platform.macos) {
      setDownloadLink('https://apps.apple.com/us/app/foxray/id6448898396');
      setChosenPaltform('macos');
    }
    if(platform.chrome && !platform.android && !platform.ios && !formfactor.tablet && !platform.macos && !platform.linux && !platform.windows) {
      setDownloadLink('https://www.google.ru/chrome/');
      setChosenPaltform('chrome');
    }
    if(platform.windows) {
      setDownloadLink('https://github.com/InvisibleManVPN/InvisibleMan-VPNClient/releases/download/1.0/InvisibleMan-x64.exe');
      setChosenPaltform('windows');
    }
    if(platform.linux) {
      setDownloadLink('https://s3.amazonaws.com/outline-releases/client/linux/stable/Outline-Client.AppImage');
      setChosenPaltform('linux');
    }

    if(platform.android) {
      setDownloadLink('https://play.google.com/store/apps/details?id=com.v2ray.ang&hl=en_US');
      setChosenPaltform('android');
    }
  }, [])

  React.useEffect(() => {
    if( chosenPlatform === 'ios') {
      setDownloadLink('https://apps.apple.com/us/app/streisand/id6450534064');
    }

    if( chosenPlatform === 'iPad') {
      setDownloadLink('https://apps.apple.com/us/app/streisand/id6450534064');
    }

    if(chosenPlatform === 'macos') {
      setDownloadLink('https://apps.apple.com/us/app/foxray/id6448898396');
    }
    if(chosenPlatform === 'android') {
      setDownloadLink('https://play.google.com/store/apps/details?id=com.v2ray.ang&hl=en_US');
    }

    if(chosenPlatform === 'chrome') {
      setDownloadLink('https://www.google.ru/chrome/');
    }

    if(chosenPlatform === 'windows') {
      setDownloadLink('https://github.com/InvisibleManVPN/InvisibleMan-VPNClient/releases/download/1.0/InvisibleMan-x64.exe');
    }

    if(chosenPlatform === 'linux') {
      setDownloadLink('https://s3.amazonaws.com/outline-releases/client/linux/stable/Outline-Client.AppImage');
    }
  }, [chosenPlatform])


  function handleClick() {
    if (progress > 1) {
      setDirection(true);
      //currentUser.activeUser ? navigate('/') : setProgress(2);
      navigate('/');
    } else {
      setIsFaded(true);
      setTimeout(setProgress, 300, (state) => ++state);
    }
  }
  React.useLayoutEffect(() => {
    progress === 2 && !currentUser.activeUser
      ? setIsDisabled(true)
      : setIsDisabled(false);
  }, [progress, currentUser.activeUser]);

  function handleBackClick() {
    if (progress === 0) {
      setDirection(false);
      currentUser.activeUser ? navigate('/') : navigate('/intro');
    } else {
      setIsFaded(true);
      progress > 0 && setTimeout(setProgress, 300, (state) => --state);
    }
  }
  function handleSwipeLeft() {
    if (progress > 1) {
      return;
    } else {
      setIsFaded(true);
      setTimeout(setProgress, 300, (state) => ++state);
    }
  }
  function handleSwipeRight() {
    if (progress === 0) {
      return;
    } else {
      setIsFaded(true);
      setTimeout(setProgress, 300, (state) => --state);
    }
  }

  const renderMacosGallery = () => {
    return (<div className='instruction__gallery-macos'>
    <div className='instruction__gallery-row first vertical'>
      <img
        className='instruction__step-image vertical'
        src={macosStepOne}
        alt='step'
      />
      <span className='instruction__step-tooltip one macos'>
        {translations[language].instruction.step1}
      </span>
      {/* <span className='instruction__step-rectangle one-vert macos' />
      <span className='instruction__step-rectangle one macos' /> */}
    </div>
    <div className='instruction__gallery-row second vertical'>
      <img
        className='instruction__step-image vertical'
        src={macosStepTwo}
        alt='step'
      />
      <span className='instruction__step-tooltip two macos'>
        {translations[language].instruction.step2}
      </span>
      {/* <span className='instruction__step-rectangle two-vert macos' />
      <span className='instruction__step-rectangle two macos' /> */}
    </div>
  </div>)
  }

  const renderIPadGallery = () => {
    return (<div className='instruction__gallery-ipad'>
    <div className='instruction__gallery-row first vertical'>
      <img
        className='instruction__step-image vertical'
        src={iPadStepOne}
        alt='step'
      />
      <span className='instruction__step-tooltip one ipad'>
        {translations[language].instruction.step1}
      </span>
      <span className='instruction__step-rectangle one-vert ipad' />
      <span className='instruction__step-rectangle one ipad' />
    </div>
    <div className='instruction__gallery-row second vertical'>
      <img
        className='instruction__step-image vertical'
        src={iPadStepTwo}
        alt='step'
      />
      <span className='instruction__step-tooltip two ipad'>
        {translations[language].instruction.step2}
      </span>
      <span className='instruction__step-rectangle two-vert ipad' />
      <span className='instruction__step-rectangle two ipad' />
      <span className='instruction__step-tooltip three ipad'>
        {translations[language].instruction.step3}
      </span>
      <span className='instruction__step-rectangle three-vert ipad' />
      <span className='instruction__step-rectangle three ipad' />
    </div>
  </div>)
  }

  const renderIosGallery = () => {
    return (<div className='instruction__gallery-ios'>
    <div className='instruction__gallery-row first horizontal'>
      <img
        className='instruction__step-image horizontal'
        src={iosStepOne}
        alt='step'
      />
      <span className='instruction__step-tooltip one ios'>
        {translations[language].instruction.step1}
      </span>
      <span className='instruction__step-rectangle one-vert ios' />
      <span className='instruction__step-rectangle one ios' />
      <span className='instruction__step-tooltip three ios'>
        {translations[language].instruction.step3}
      </span>
      <span className='instruction__step-rectangle three-vert ios' />
      <span className='instruction__step-rectangle three ios' />
    </div>
    <div className='instruction__gallery-row second horizontal'>
      <img
        className='instruction__step-image horizontal'
        src={iosStepTwo}
        alt='step'
      />
      <span className='instruction__step-tooltip two ios'>
        {translations[language].instruction.step2}
      </span>
      <span className='instruction__step-rectangle two-vert ios' />
      <span className='instruction__step-rectangle two ios' />
    </div>
  </div>)
  }

  const renderAndroidGallery = () => {
    return (<div className='instruction__gallery-android'>
    <div className='instruction__gallery-row first horizontal'>
      <img
        className='instruction__step-image android'
        src={androidStepOne}
        alt='step'
      />
      <span className='instruction__step-tooltip one android'>
        {translations[language].instruction.step1}
      </span>
      <span className='instruction__step-rectangle one-vert android' />
      <span className='instruction__step-rectangle one android' />
      <span className='instruction__step-tooltip three android'>
        {translations[language].instruction.step3}
      </span>
      <span className='instruction__step-rectangle three-vert android' />
      <span className='instruction__step-rectangle three android' />
    </div>
    <div className='instruction__gallery-row second horizontal'>
      <img
        className='instruction__step-image android'
        src={androidStepTwo}
        alt='step'
      />
      <span className='instruction__step-tooltip two android'>
        {translations[language].instruction.step2}
      </span>
      <span className='instruction__step-rectangle two-vert android' />
      <span className='instruction__step-rectangle two android' />
    </div>
  </div>)
  }

  const renderWindowsGallery = () => {
    return (<div className='instruction__gallery-windows'>
    <div className='instruction__gallery-row first windows'>
      <div className='instruction__step-image-container windows'>
        <img
          className='instruction__step-image windows'
          src={windowsStepOne}
          alt='step'
        />
      </div>
      <span className='instruction__step-tooltip one windows'>
        {translations[language].instruction.step1}
      </span>
      {/* <span className='instruction__step-rectangle one-vert windows' />
      <span className='instruction__step-rectangle one windows' /> */}
      <div className='instruction__step-image-container windows'>
        <img
          className='instruction__step-image windows'
          src={windowsStepTwo}
          alt='step'
        />
      </div>
      <span className='instruction__step-tooltip two windows'>
        {translations[language].instruction.step2}
      </span>
      {/* <span className='instruction__step-rectangle two-vert windows' />
      <span className='instruction__step-rectangle two windows' /> */}
    </div>
    <div className='instruction__gallery-row second windows'>
    <div className='instruction__step-image-container windows'>
        <img
          className='instruction__step-image windows'
          src={windowsStepThree}
          alt='step'
        />
      </div>
      <span className='instruction__step-tooltip three windows'>
        {translations[language].instruction.step3}
      </span>
      {/* <span className='instruction__step-rectangle three-vert windows' />
      <span className='instruction__step-rectangle three windows' /> */}
      <div className='instruction__step-image-container windows'>
      <img
        className='instruction__step-image windows'
        src={windowsStepFour}
        alt='step'
      />
      </div>
    </div>
  </div>)
  }

  const galleryMapping = {
    'ios': renderIosGallery,
    "iPad": renderIPadGallery,
    'macos': renderMacosGallery,
    'android': renderAndroidGallery,
    'windows': renderWindowsGallery
  }

  return (
    <motion.section
      {...handlers}
      className='instruction'
      initial={direction ? 'fromLeft' : 'fromRight'}
      animate={{ x: 0, opacity: 1, transition: { duration: 0.2, delay: 0.2 } }}
      exit={direction ? 'exitToRight' : 'exitToLeft'}
      variants={directionVariants}
    >
      <button onClick={handleBackClick} className={`instruction__button-top ${progress === 0 ? 'first' : ''}`}>
        {translations[language].backButton}
        <span className={`instruction__button-corner ${progress === 0 ? 'first' : ''}`}></span>
      </button>
      <motion.div
        className={`instruction__content ${progress === 2 ? "last_instruction" : ""}`}
        initial={{ opacity: 0 }}
        animate={isFaded ? 'faded' : 'visible'}
        variants={variants}
      >
        {progress === 0 && (
          <>
            
            {platform.android ? ( <p className='instruction__text'>
              
              {translations[language].instruction.p1TitleBeginAndroid}
              <span className='instruction__text_colored'>
                {translations[language].instruction.p1TitleMiddle1}
              </span>
                {translations[language].instruction.p1TitleEnd} 
            
            </p>) : ( <p className='instruction__text'>
              
              {translations[language].instruction.p1TitleBegin}
             
              <span className='instruction__text_colored'>
                {translations[language].instruction.p1TitleMiddle1}
              </span>
              <br /> {translations[language].instruction.p1TitleEnd} 
            
            </p>)}
            <CopyToClipboardField
              currentClass='instruction__copy-to-clipboard'
              data={currentUser.link}
              gaAction='Install_copy'
            />
            <div className='instruction__text-case'>
              <p className='instruction__text'>
                {translations[language].instruction.p2TitleBegin}
                <span className='instruction__text_colored'>
                  {' '}
                  {translations[language].instruction.p2TitleMiddle}
                </span>
                {translations[language].instruction.p2TitleEnd}
              </p>
              <p className='instruction__text-tip'>
                {translations[language].instruction.p2Text}
              </p>
            </div>
            <div className='instruction__link-box'>
         
              <div className='instruction__link-row'>
                <div onClick={() => setChosenPaltform('ios')} className='instruction__link-item'>
                  <img className={chosenPlatform === 'ios' ? 'instruction__link-focused' : 'instruction__link-img'} src={appleDownload} alt='app store' />
                  <span className={chosenPlatform === 'ios' ? 'instruction__link-name-focused' : 'instruction__link-name'}>iOS</span>
                </div>
                <div onClick={() => setChosenPaltform('iPad')} className='instruction__link-item'>
                  <img className={chosenPlatform === 'iPad' ? 'instruction__link-focused' : 'instruction__link-img'} src={appleDownload} alt='app store' />
                  <span className={chosenPlatform === 'iPad' ? 'instruction__link-name-focused' : 'instruction__link-name'}>iPad</span>
                </div>
                <div onClick={() => setChosenPaltform('macos')} className='instruction__link-item'>
                  <img className={chosenPlatform === 'macos' ? 'instruction__link-focused' : 'instruction__link-img'} src={appleDownload} alt='app store' />
                  <span className={chosenPlatform === 'macos' ? 'instruction__link-name-focused' : 'instruction__link-name'}>MacOS</span>
                </div>
              </div>
              <div className='instruction__link-row_bottom'>
                <div onClick={() => setChosenPaltform('android')} className='instruction__link-item_bottom'>
                  <img className={chosenPlatform === 'android' ? 'instruction__link-focused' : 'instruction__link-img'} src={androidDownload} alt='google play' />
                  <span className={chosenPlatform === 'android' ? 'instruction__link-name-focused' : 'instruction__link-name'}>Android</span>
                </div>
                
                <div onClick={() => setChosenPaltform('windows')} className='instruction__link-item_bottom'>
                  <img className={chosenPlatform === 'windows' ? 'instruction__link-focused' : 'instruction__link-img'} src={windowsDownload} alt='outline website' />
                  <span className={chosenPlatform === 'windows'? 'instruction__link-name-focused' : 'instruction__link-name'}>Windows</span>
                </div>
              </div>

            </div>
          </>
        )}
        {progress === 1 && (
          <>
            <p className='instruction__text'>
              {translations[language].instruction.p3TitleBegin}
              {translations[language].instruction.p3TitleMiddle}
              <span className='instruction__text_colored'>
                {' '}
                {softTitles[chosenPlatform]}{' '}
              </span>
              {translations[language].instruction.p3TitleEnd}
            </p>
            {galleryMapping[chosenPlatform]()}
          </>
        )}
        {progress === 2 && (
          <>
            <span className='instruction__title'>
              {translations[language].instruction.p4TitleBegin}
              <br />
              {translations[language].instruction.p4TitleEnd}
            </span>
            <p className='instruction__text text_final_stage'>
              {translations[language].instruction.p4TextBegin}
              </p>
          </>
        )}
      </motion.div>
      <div className='instruction__button-container'>
        <motion.div
          className='instruction__button-wrapper'
          style={{ pointerEvents: progress === 2 ? 'all' : 'none', display: progress === 2 ? 'flex' : 'none' }}
          animate={progress === 2 ? 'visible' : 'hidden'}
          variants={buttonVariants}
        >
          <AppButton
            currentClass='border-blue secondary blue instruction_button'
            text={translations[language].appButton.toTelegram}
            handler={() => tg.close()}
          />
        </motion.div>

        <motion.div
          className={`instruction__button-wrapper ${progress > 0 ? 'hidden' : ''}`}
          style={{ pointerEvents: progress === 0 ? 'all' : 'none' }}
          animate={progress === 0 ? 'visible' : 'hidden'}
          variants={buttonVariants}
        >
          <AppButton
                currentClass='primary border-blue blue instruction_button'
                text={translations[language].appButton.install}
                handler={() => window.open(dowloadLink, '_blank')}
                onClick={() => setNextDisabled(false)}
              />
          </motion.div>
          <AppButton
          currentClass={`primary white bg-blue margin-top ${isDisabled && 'disabled'} instruction_button`}
          text={`${
            progress < 2
              ? translations[language].appButton.next
              : translations[language].appButton.mainMenu
          }`}
          handler={handleClick}
        />
        {progress === 2 && isDisabled ? (
          <span className='instruction__tips'>
            {translations[language].instruction.p4NotActiveUserTips}
          </span>
        ) : null}
      </div>
    </motion.section>
  );
}

export default Instruction;
