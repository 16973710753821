import React from 'react';
import './Traffic.css';
import AppButton from '../AppButton/AppButton';
import BackButton from '../BackButton/BackButton';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { directionVariants } from '../../utils/directionOptions';
import { setDirection } from '../../redux/actions/actions';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import { parseTimestamp, getBonus, roundTraffic, getRefTraffic, getIsFree, getTariffEnded, getOnlyOwn, getNextGb } from '../../utils/helpers';
import { translations } from '../../utils/translations/translations';
import cross from '../../images/crossTraffic.svg'

function Traffic() {
const currentUser = useSelector((state) => state.currentUser);
const language = useSelector(state => state.language);
const remoteData = useSelector((state) => state.remoteData);
// 1679077973
// const currentUser = {
//   tariff: 'nolimit',
//   endActiveDate: 	1679077973 * 1000,
//   bonusDate: 1677921307591.322,
//   smart: 0,
//   smartTraffic: 0,
//   regularTraffic: 5,
//   bonusTrafficNow: '5_regular',
//   bonusTrafficNextMonth: 0,
//   referralTraffic: 0,
//   daysLeft: 5,
//   trafficMean: 0,
//   trafficForecast: 0
// }
  const navigate = useNavigate();

  const today = new Date();
  const isNolimit = currentUser.tariff.replace(/[^a-zA-Z]+/g, '').toLowerCase() === 'nolimit';
  const isNolimitReferral = currentUser.tariff.replace(/[^a-zA-Z]+/g, '').toLowerCase() === 'nolimit' && (currentUser.endActiveDate < today) && currentUser.referralTraffic > 0

  const isFree = getIsFree(currentUser, getBonus);
  const isEmpty = getTariffEnded(currentUser, getBonus, isFree);
  const onlyOwn = getOnlyOwn(currentUser, isEmpty, remoteData?.data?.endDate);
  const nextFit = getNextGb(currentUser.firstFitTariff);
  const direction = useSelector((state) => state.direction);

  return (
    <motion.section
      className='traffic'
      initial={direction ? 'fromLeft' : 'fromRight'}
      animate={{ x: 0, opacity: 1, transition: { duration: 0.2, delay: 0.2 } }}
      exit={direction ? 'exitToRight' : 'exitToLeft'}
      variants={directionVariants}
    >
      <BurgerMenu color='var(--white)' />
      <BackButton text='Назад' path={-1} currentClass='white' title='Трафик' />
      
      <div className='traffic__main'>
        {isNolimit && !isNolimitReferral || onlyOwn ? (
          <div className='traffic__main-content'>
            <span className='traffic__main_text'>
              {currentUser.endActiveDate < today || remoteData.endDate < today ? (
                translations[language].textTips.tariff +
                translations[language].tariffes.tariffEnded
              ) :
              (translations[language].textTips.activeUntil +
               (onlyOwn ? parseTimestamp(remoteData.endDate) : parseTimestamp(currentUser.endActiveDate)))}
            </span>
            {onlyOwn ? <span className='traffic__main_value nolimit'>{remoteData?.data?.trafficTotal}</span>  : <span className='traffic__main_value nolimit'>NOLIMIT</span>}
            <span className='traffic__main_text'>
              {translations[language].traffic.nolimitTip}
            </span>
          </div>
        ) : (!isEmpty || isNolimitReferral)
            ? (
            <div className='traffic__main-content'>
              <span className='traffic__main_text'>
                {isNolimitReferral ? '' : translations[language].traffic.enoughTo +
                  parseTimestamp(currentUser.endDate) 
                }
              </span>
              <span className='traffic__main_value'>
                {((currentUser.smartTraffic + currentUser.regularTraffic + parseInt(getRefTraffic(currentUser.referralTraffic)) + nextFit).toFixed(1)) + translations[language].textTips.gb}
              </span>
              <span className='traffic__main_text'>
                {translations[language].traffic.toEnd}
              </span>
            </div>)
            : (<div className='traffic__main-content'>
            <span className='traffic__main_value empty'>{translations[language].traffic.empty}</span>
         </div>)
          }
        {isNolimit ? null
          : ( <div className='traffic__outlook'>
          <div className='traffic__outlook-element'>
            <span className='traffic__outlook-element-text'>
              {translations[language].traffic.averagePerDay}
            </span>
            <span className='traffic__outlook-element-value'>
              {(isEmpty && !onlyOwn ? '0' : currentUser.trafficMean) + translations[language].textTips.gb}
            </span>
          </div>
          <div className='traffic__outlook-element'>
            <span className='traffic__outlook-element-text'>
              {translations[language].traffic.forecast}
            </span>
            <span className='traffic__outlook-element-value'>
              {(isEmpty && !onlyOwn ? '0' : currentUser.trafficForecast) + translations[language].textTips.gb}
            </span>
          </div>
        </div>)}
        {/* <div className='traffic__history'>
          <span className='traffic__history-title'>{translations[language].traffic.history1} <br/> {translations[language].traffic.history2}</span>
          <div className='traffic__history-dots'>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className='traffic__history-item'>
          <div className='traffic__history-cross'>
            <img className='traffic__history-cross-img' src={cross} />
          </div>
          <div className='traffic__history-label'>
            <span className='traffic__history-label-item'>{translations[language].traffic.historyLabel1}</span>
            <span className='traffic__history-label-item'>{translations[language].traffic.historyLabel2}</span>
            <span className='traffic__history-label-item'>{translations[language].traffic.historyLabel2}</span>
          </div>
          <div className='traffic__history-days'>
            <div className='traffic__history-day'>
              <span className='traffic__history-date'>30.07</span>
              <span className='traffic__history-spend'>0,5 Гб</span>
              <span className='traffic__history-left'>6 Гб</span>
            </div>
            <div className='traffic__history-day'>
              <span className='traffic__history-date'>29.07</span>
              <span className='traffic__history-spend'>0,5 Гб</span>
              <span className='traffic__history-left'>6 Гб</span>
            </div>
            <div className='traffic__history-day'>
              <span className='traffic__history-date'>28.07</span>
              <span className='traffic__history-spend'>0,5 Гб</span>
              <span className='traffic__history-left'>6 Гб</span>
            </div>
          </div>
        </div> */}
      </div>
      <div className='traffic__secondary-content'>
        {isNolimit ? (
          <AppButton
            text={translations[language].appButton.continueTariff}
            currentClass='primary rose wide margin-bottom'
            handler={() => {
              setDirection(true);
              navigate('/tariffes/nolimit');
            }}
          />
        ) : (
          <>
            <AppButton
              text={translations[language].appButton.addGb}
              currentClass={`primary rose wide margin-bottom ${
                isNolimit ? 'disabled' : ''
              }`}
              handler={() => {
                setDirection(true);
                currentUser.activeUser
                  ? navigate('/tariffes/fit')
                  : navigate('/tariffes');
              }}
            />
            <div className='traffic__button-box'>
              <AppButton
                text={translations[language].appButton.earn}
                currentClass='secondary white narrow'
                handler={() => {
                  setDirection(true);
                  navigate('/referral');
                }}
              />
              <AppButton
                text={translations[language].appButton.changeTariff}
                currentClass='secondary white narrow'
                handler={() => {
                  setDirection(true);
                  navigate('/tariffes');
                }}
              />
            </div>
          </>
        )}
        <p className='traffic__tips'>
          {/* {translations[language].traffic.dontWorry}
          <span
            onClick={() => {
              setDirection(true);
              navigate('/balance');
            }}
            className='traffic__link'
          >
            {translations[language].traffic.addBalance}
          </span>
          {translations[language].traffic.startAutoCharge} */}
          {translations[language].traffic.dontWorry}
        </p>
      </div>
    </motion.section>
  );
}

export default Traffic;
